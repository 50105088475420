import request from "@/api/request";

export function getAlarmPanels(orgId) {
    return request({
        url: orgId > 1 ? `/alarmpanels/filter-by-org/${orgId}` : `/alarmpanels`,
        method: 'get'
    })
}

export function getAlarmPanel(name) {
    return request({
        url: `/alarmpanels/panel?panel-name=${name}`,
        method: 'get'
    })
}

export function createAlarmPanel(data) {
    return request({
        url: `/alarmpanels`,
        method: 'post',
        data: data
    })
}

export function updateAlarmPanel(data) {
    return request({
        url: `/alarmpanels`,
        method: 'put',
        data: data
    })
}

export function deleteAlarmPanel(name) {
    return request({
        url: `/alarmpanels?panel-name=${name}`,
        method: 'delete',
    })
}

export function activateAlarmPanel(data) {
    data.button.icon = undefined
    return request({
        url: `/alarmpanels/activate`,
        method: 'post',
        data: data
    })
}
